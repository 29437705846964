<template>
  <div v-if="reportTemplateImage" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="reportTemplateImage">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <legend>
            <strong>{{ $t('templateAttachments') }}</strong>
          </legend>

          <DropzoneUpload
            :complete="isAttachmentUploadComplete"
            :parentId="reportTemplateImage.id"
            parentType="templateAttachments"
            :uploadCallback="uploadReportTemplateImageAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />
          <AttachmentGallery
            :attachments="reportTemplateImage.templateAttachments"
            attachmentType="templateAttachments"
            v-on:attachment-list:delete="onDeleteReportTemplateImageAttachment"
          ></AttachmentGallery>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <b-form-group class="form-action">
            <b-row>
              <b-col class="text-right">
                <router-link :to="{ name: 'ReportTemplateImagesPage' }">
                  <b-button :disabled="buttonsDisabled" class="mr-xs" variant="default"
                    >{{ $t('cancel') }}
                    <LoadingSpinner :size="15" />
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';
import Widget from '@/components/Widget/Widget';

export default {
  name: 'ReportTemplateImageEditPage',
  components: {
    Breadcrumbs,
    LoadingSpinner,
    DropzoneUpload,
    AttachmentGallery,
    Widget,
  },
  props: {
    reportTemplateImageNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isAttachmentUploadComplete: false,
      number: null,
      user: {},
      buttonsDisabled: false,
    };
  },
  computed: {
    ...mapGetters(['getReportTemplateImageByNumber']),
    reportTemplateImage() {
      return this.getReportTemplateImageByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Berichte' },
        { name: 'Beispielbilder' },
        {
          name: this.reportTemplateImage.number
            ? this.$t(this.reportTemplateImage.category) + ' bearbeiten'
            : 'erstellen',
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchReportTemplateImageByNumber',
      'deleteReportTemplateImageAttachment',
      'uploadReportTemplateImageAttachment',
    ]),
    async save() {
      try {
        if (this.reportTemplateImage.number && this.reportTemplateImage.number != '_new') {
          await this.updateReportTemplateImage(this.reportTemplateImage);
        } else {
          await this.createReportTemplateImage(this.reportTemplateImage);
          this.$router.push({ name: 'ReportTemplateImagesPage' });
        }
      } catch (e) {
        // console.log(e);
      }
    },
    async saveReportTemplateImage() {
      await this.save();
    },
    async saveReportTemplateImageAndBack() {
      await this.save();
      this.$router.push({ name: 'ReportTemplateImagesPage' });
    },
    async remove(reportTemplateImageNumber) {
      if (reportTemplateImageNumber === this.reportTemplateImage.number) {
        this.deleteReportTemplateImage(this.reportTemplateImage.number);
        await this.fetchReportTemplateImages();
        this.$router.push({ name: 'ReportTemplateImagesPage' });
      }
    },

    onDeleteReportTemplateImageAttachment(event) {
      this.deleteReportTemplateImageAttachment({
        reportTemplateImageId: this.reportTemplateImage.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
  },
  async mounted() {
    this.number = this.reportTemplateImageNumber;
    await this.fetchReportTemplateImageByNumber(this.number);

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadReportTemplateImageAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
  /**
   * if only parameters of the route have changed the component will be reused
   * we have to update all the data before the route updates
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteUpdate(to, from, next) {
    // console.log('Route params', to.params);
    // console.log('Current reportTemplateImage number', this.reportTemplateImageNumber);
    if (
      to.params.reportTemplateImageNumber &&
      to.params.reportTemplateImageNumber !== this.reportTemplateImageNumber
    ) {
      this.$store.commit('SET_POWER_COMPANY_DEFAULT_STATE');
      this.number = to.params.reportTemplateImageNumber;
      await this.fetchReportTemplateImageByNumber(this.number);
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scoped lang="scss">
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
.ck-content {
  min-height: 80px;
}
</style>
