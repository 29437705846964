<template>
  <div class="loading-spinner">
    <div v-if="isLoading" class="loading-spinner level-item has-text-centered">
      <Spinner :size="size" class=""></Spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Spinner from './Spinner';
export default {
  name: 'LoadingSpinner',
  props: {
    size: {
      default: 25,
    },
  },
  components: { Spinner },
  computed: {
    ...mapGetters(['isLoading', 'isSuccess']),
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  display: inline-block;
}
</style>
